// All code in this file needs to be compatible with obsolete browsers; everything < IE11.

// https://webpack.js.org/guides/public-path/
__webpack_public_path__ = document.body.className.indexOf('cms') > -1 || document.body.className.indexOf('preview') > -1 ? '/web/BVM/BVM/Templates/' : '/';

console.log('Hello, from BVM!');

// Required Promises (e.g. import()) in IE11.
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

function isObsoleteBrowser() {
  const Browser = (function() {
    'use strict';

    let version = -1;
    let isIE = false;
    const map = {
      '5.6': 6,
      '5.7': 7,
      '5.8': 8,
      '9': 9,
      '10': 10,
      '11': 11
    };
    const jscriptVersion = new Function('/*@cc_on return @_jscript_version; @*/')();

    if(jscriptVersion !== undefined || !!window.msCrypto) {
      isIE = true;
      version = map[jscriptVersion || '11'];
    }

    return {
      isIE: isIE,
      version: version
    };
  }());
  return Browser.isIE && Browser.version < 11;
}

if(isObsoleteBrowser()) {
  window.location.replace('/obsolete-browser');
} else {
  import(/* webpackChunkName: "app" */ './App.js').then(app => app.start()).catch(e => console.log(e));
}